<template>
  <div id="collection">
    <el-row>
      <el-col :span="24">
        <div class="content">
          <router-link to="/Selection">
            <el-button
              class="btnbox"
              icon="el-icon-s-goods"
              type="primary"
              size="small"
              >精品货源</el-button
            ></router-link
          >

          <h3>一键采集</h3>

          <el-form ref="form" :model="form" label-width="80px" size="medium">
            <el-form-item label="产品链接">
              <el-input
                :rows="5"
                placeholder="请输入pc淘宝天猫产品链接，京东产品链接,多条产品请换行，提交后系统会自动采集，请到小店后台上架，刷新可见结果"
                type="textarea"
                v-model="form.desc"
              ></el-input>
            </el-form-item>

            <el-form-item label="产品类目">
              <el-cascader
                v-model="chooseVal"
                :options="options"
                :props="{
                  expandTrigger: 'hover',
                  value: 'label',
                  label: 'label'
                }"
                @change="handleChange"
              ></el-cascader>

              <span class="recont">最近使用:</span>

              <el-cascader
                v-model="form.recentval"
                :options="recentList"
                :props="{
                  expandTrigger: 'hover',
                  value: 'id',
                  label: 'leimu'
                }"
                @change="recentChange"
              ></el-cascader>

               <div style="display: inline-block;margin-bottom:20px">
                   <span class="recont">搜索类目:</span>

              <el-autocomplete
               
                v-model="state2"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
               </div>


            </el-form-item>

            <el-form-item label="价格变动">
              <el-select v-model="form.region" placeholder="请选择价格">
                <el-option
                  v-for="(item, index) in price"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="运费模版">
              <el-select v-model="form.shipping" placeholder="请选择运费模版">
                <el-option
                  v-for="(item, index) in shipping"
                  :key="index"
                  :label="item.name"
                  :value="item.template_id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="支持平台">
              <a href="https://www.taobao.com/" target="_bank" class="icon">
                <img src="./../assets/images/t.png" alt="" />
              </a>
              <a href="https://www.taobao.com/" target="_bank" class="icon">
                <img src="./../assets/images/m.png" alt="" />
              </a>
              <a href="https://www.taobao.com/" target="_bank" class="icon">
                <img src="./../assets/images/j.png" alt="" />
              </a>

              <a href="https://m.pinduoduo.com/" target="_bank" class="icon">
                <img src="./../assets/images/pdd.png" alt="" />
              </a>
              <a href="https://www.1688.com/" target="_bank" class="icon">
                <img
                  src="./../assets/images/1688.png"
                  style="width:80px;object-fit: contain;"
                  alt=""
                />
              </a>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即采集</el-button>
            </el-form-item>
          </el-form>
          <p class="tips">
            提示：请遵守平台相关规范，使用者请获取授权后再进行商品复制。
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss">
.recont {
  margin: 0 10px;
}
</style>
<script>
import json from "./../assets/json/choose.json";
export default {
  name: "collection",
  data() {
    return {
      form: {
        desc: "",
        region: "1.1",
        shipping: "",
        options: "",
        recentval: "",
        one_cate: "",
        two_cate: "",
        three_cate: ""
      },

      state2:'',
      stateList:[],

      chooseVal: [],

      recentList: [],

      price: [
        {
          value: "1",
          name: "不加价"
        },
        {
          value: "1.05",
          name: "+5%"
        },
        {
          value: "1.1",
          name: "+10%"
        },
        {
          value: "1.2",
          name: "+20%"
        },
        {
          value: "1.3",
          name: "+30%"
        },
        {
          value: "1.4",
          name: "+40%"
        },
        {
          value: "1.5",
          name: "+50%"
        },
        {
          value: "0.9",
          name: "-10%"
        },
        {
          value: "0.8",
          name: "-20%"
        },
        {
          value: "0.7",
          name: "-30%"
        },
        {
          value: "0.5",
          name: "-50%"
        }
      ],
      shipping: []
    };
  },
  methods: {
      querySearch(queryString, cb) {
        if(queryString){
          this.axios.get(this.api.search,{
          params:{
            keyword:queryString
          }
        })
        .then((res)=>{
          res.forEach((items)=>{
            items.value=items.one_name+'|'+items.two_name+'|'+items.three_name
          });

          this.stateList=res;
         
          cb(res)
        })
        }
        
     
      },
      handleSelect(e){
        this.form.one_cate=e.one;
        this.form.two_cate=e.two;
        this.form.three_cate=e.three;
        this.chooseVal=[e.one_name,e.two_name,e.three_name]
      },
    onSubmit() {
      if (this.form.one_cate==''||this.form.two_cate=='') {
        this.$message({
          message: "请选择完整类目",
          type: "warning"
        });

        return false;
      }

      let { three_cate, two_cate, one_cate } = this.form;

      let json = {
        leimu: this.chooseVal.join("|"),
        template_id: this.form.shipping,
        shopid: this.$store.state.userinfo.id,
        three_cate,
        two_cate,
        one_cate,
        keyword: this.form.desc,
        jiajia: this.form.region
      };

      let data = new FormData();

      data.append("leimu", json.leimu);
      data.append("template_id", json.template_id);
      data.append("shopid", json.shopid);
      data.append("three_cate", json.three_cate);
      data.append("two_cate", json.two_cate);
      data.append("one_cate", json.one_cate);
      data.append("keyword", json.keyword);
      data.append("jiajia", json.jiajia);

      const _this = this;

      if (three_cate == "") {
        this.axios
          .get(this.api.getType, {
            params: {
              f_cat_id: two_cate
            }
          })
          .then(res => {
            if (res != "") {
            
               data.append("three_cate", res[0].cat_id);
            } else {
              this.$message({
                message: "请先申请类目权限",
                type: "warning"
              });
            }
          })
          .then(() => {
            this.axios.post(this.api.add, data).then(res => {
              if (res.code == 1) {
                this.$message({
                  message: "采集成功",
                  type: "success",
                  onClose: function() {
                    _this.$router.push("/logs");
                  }
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                
                });
              }
            });
          });
      } else {
        this.axios.post(this.api.add, data).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "采集成功",
              type: "success",
              onClose: function() {
                _this.$router.push("/productlist");
              }
            });
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
             
            });
          }
        });
      }
    },

    recent() {
      this.axios.get(this.api.recent).then(res => {
        this.recentList = res;
      });
    },

    handleChange(val) {
      let leimuNum = this.options.find(item => {
        return item.label == val[0];
      });

      this.form.one_cate = leimuNum.value;

      if (leimuNum.children) {
        leimuNum.children.forEach(item => {
          if (item.label == this.chooseVal[1]) {
            this.form.two_cate = item.value;
            if (item.children) {
              item.children.forEach(i => {
                if (i.label == this.chooseVal[2]) {
                  this.form.three_cate = i.value;
                }
              });
            } else {
              this.form.three_cate = "";
            }
          }
        });
      }

      this.form.recentval = [];
    },
    recentChange(val) {
      console.log(val);
      this.recentList.forEach(i => {
        if (i.id == val) {
          this.form.one_cate = i.one_cate;
          this.form.two_cate = i.two_cate;
          this.form.three_cate = i.three_cate;

          // this.chooseVal=[i.one_name,i.two_name,i.three_name]
        }
      });
      this.chooseVal = [];
    },

    getShipping() {
      this.axios.get(this.api.shipping).then(res => {
        if (res.code == "-100") {
          window.location.href = this.api.loginout;
        } else {
          this.shipping = res;
          this.form.shipping = res[0].template_id;
        }
      });
    }
  },
  created() {
    this.options = json;

    this.getShipping();
    this.recent();
    this.form.region = this.price[2].value;
  },
  mounted() {
    document.getElementsByClassName("el-cascader-panel")[0].style.height ="200px";
  }
};
</script>